/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    h2: "h2",
    br: "br",
    h3: "h3",
    ol: "ol",
    a: "a",
    h4: "h4",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {ContentFilters, Admonition, FilteredContent, RequestParameters, RequestSample, ResponseBody, ResponseSample} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  if (!ContentFilters) _missingMdxReference("ContentFilters", true);
  if (!FilteredContent) _missingMdxReference("FilteredContent", true);
  if (!RequestParameters) _missingMdxReference("RequestParameters", true);
  if (!RequestSample) _missingMdxReference("RequestSample", true);
  if (!ResponseBody) _missingMdxReference("ResponseBody", true);
  if (!ResponseSample) _missingMdxReference("ResponseSample", true);
  return React.createElement(React.Fragment, null, React.createElement(ContentFilters, {
    filters: ["Request parameters", "Request samples", "Response body", "Response sample"]
  }), "\n", React.createElement(Admonition, {
    type: "warning"
  }, React.createElement(_components.p, null, "You must create a merchant platform before you can add additional processing accounts.")), "\n", React.createElement(_components.p, null, "Each merchant platform includes one or more processing accounts that run transactions for the business. To create a processing account, you must provide the following types of information:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Business details"), " including the Merchant Category Code (MCC), Doing Business As (DBA) name, and address of the business."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Owners’ details"), " including their names, addresses, and contact details. You must assign a control prong who is responsible for their account."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Processing details"), " including estimated average transaction amounts and monthly processing amounts."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Pricing and funding details"), " including pricing agreements and funding accounts for the processing account."), "\n"), "\n", React.createElement(Admonition, {
    type: "important"
  }, React.createElement(_components.p, null, "You can add more than one processing account in the same request.")), "\n", React.createElement(_components.h2, null, "Integration steps"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Step 1."), " Create a processing account", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Step 2."), " (Optional) Create a reminder"), "\n", React.createElement(_components.h2, null, "Before you begin"), "\n", React.createElement(_components.h3, null, "Bearer tokens"), "\n", React.createElement(_components.p, null, "Use our Identity Service to generate a Bearer token to include in the header of your requests. To generate your Bearer token, complete the following steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Include your API key in the x-api-key parameter in the header of a POST request."), "\n", React.createElement(_components.li, null, "Send your request to ", React.createElement(_components.a, {
    href: "https://identity.payroc.com/authorize"
  }, "https://identity.payroc.com/authorize"), "."), "\n"), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You need to generate a new Bearer token before the previous Bearer token expires.")), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h4, null, "Example request"), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl --location --request POST  'https://identity.payroc.com/authorize' --header 'x-api-key: <api key>'\n"))), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(_components.h4, null, "Example response"), React.createElement(_components.p, null, "If your request is successful, we return a response that contains your Bearer token, information about its scope, and when it expires."), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n\"access_token\": \"eyJhbGc....adQssw5c\",\n\"expires_in\": 3600,\n\"scope\": \"service_a service_b\",\n\"token_type\": \"Bearer\"\n}\n"))), "\n", React.createElement(_components.h3, null, "Headers"), "\n", React.createElement(_components.p, null, "To create the header of each POST request, you must include the following parameters:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Content-Type:"), " Include application/json as the value for this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Authorization:"), " Include your Bearer token in this parameter."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Idempotency-Key:"), " Include a UUID v4 to make the request idempotent."), "\n"), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "curl\n-H \"Content-Type: application/json\"\n-H \"Authorization: <Bearer token>\"\n-H \"Idempotency-Key: <UUID v4>\"\n"))), "\n", React.createElement(_components.h3, null, "Errors"), "\n", React.createElement(_components.p, null, "If your request is unsuccessful, we return an error. For more information about errors, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/errors"
  }, "Errors"), "."), "\n", React.createElement(_components.h2, null, "Step 1. Create a processing account"), "\n", React.createElement(_components.p, null, "Send a POST request with the merchantPlatformId to our Merchant Platform endpoint:", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Test endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/merchant-platforms/%7BmerchantPlatformId%7D/processing-accounts"
  }, "https://api.uat.payroc.com/v1/merchant-platforms/{merchantPlatformId}/processing-accounts"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/merchant-platforms/%7BmerchantPlatformId%7D/processing-accounts"
  }, "https://api.payroc.com/v1/merchant-platforms/{merchantPlatformId}/processing-accounts")), "\n", React.createElement(_components.h3, null, "Request parameters"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(RequestParameters, {
    operationId: "createProcessingAccount"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "createProcessingAccount"
  })), "\n", React.createElement(_components.h3, null, "Response fields"), "\n", React.createElement(_components.p, null, "If your request is successful, our gateway creates the processing account. The response contains the following fields:"), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(ResponseBody, {
    operationId: "createProcessingAccount"
  })), "\n", React.createElement(_components.h3, null, "Example response"), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createProcessingAccount"
  })), "\n", React.createElement(_components.h2, null, "Step 2. (Optional) Create a reminder"), "\n", React.createElement(_components.p, null, "If you requested the merchant’s signature by email and they don’t respond, use our Reminders endpoint to send another email."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, "You can use the Reminders endpoint only if you request the merchant’s signature by email. If you generate a link to the pricing agreement, you can’t use the Reminders endpoint.")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Test endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/processing-accounts/%7BprocessingAccountId%7D/reminders"
  }, "https://api.uat.payroc.com/v1/processing-accounts/{processingAccountId}/reminders"), React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "Production endpoint"), ": ", React.createElement(_components.a, {
    href: "https://api.payroc.com/v1/processing-accounts/%7BprocessingAccountId%7D/reminders"
  }, "https://api.payroc.com/v1/processing-accounts/{processingAccountId}/reminders")), "\n", React.createElement(_components.h3, null, "Request parameters"), "\n", React.createElement(FilteredContent, {
    filter: "Request parameters"
  }, React.createElement(RequestParameters, {
    operationId: "createReminder"
  })), "\n", React.createElement(FilteredContent, {
    filter: "Request samples"
  }, React.createElement(_components.h3, null, "Example request"), React.createElement(RequestSample, {
    operationId: "createReminder"
  })), "\n", React.createElement(_components.h3, null, "Response fields"), "\n", React.createElement(_components.p, null, "If your request is successful, our gateway creates the reminder and sends the email to the merchant. The response contains the following fields:"), "\n", React.createElement(FilteredContent, {
    filter: "Response body"
  }, React.createElement(ResponseBody, {
    operationId: "createReminder"
  })), "\n", React.createElement(_components.h3, null, "Example response"), "\n", React.createElement(FilteredContent, {
    filter: "Response sample"
  }, React.createElement(ResponseSample, {
    operationId: "createReminder"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
